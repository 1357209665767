// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "lightgallery.js";
import "lightgallery.js/dist/css/lightgallery.min.css";

import "stylesheets/application"
const images = require.context('../images', true)
import "trix"
import "@rails/actiontext"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

document.addEventListener("turbolinks:load", function(event) {
  const menuEl = document.getElementById('menu')
  const menuToggleEl = document.getElementById('menu-toggle')

  if(menuToggleEl) menuToggleEl.onchange = () => menuEl.classList.toggle('hidden');

  if (typeof gtag === 'function') {
    gtag('config', 'UA-77115395-1', {
      'page_location': event.data.url,
      'page_path': event.target.location.pathname,
      'page_title': event.target.title
    })
  }
})